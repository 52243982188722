import React, { useContext, useEffect, useState } from "react";
import DefaultPic from "../../Images/defualtProPic.jpg";
import ViewMore from "../../Images/viewMore.svg";
import likeIcon from '../../Images/LikeIcon.svg'
import bookmark1 from '../../Images/bookMark.svg'
import bookmark2 from '../../Images/almostBanner.svg'
import bookmark3 from '../../Images/bookmarked.svg'
import bookmark4 from '../../Images/almostRemoveBookmark.svg'
import clikeIcon from '../../Images/clickedLikeIcon.svg'
import commentIcon from '../../Images/chat.svg'
import shareIcon from '../../Images/share.svg'
import whatsapp from '../../Images/whatsapp.svg'
import email from '../../Images/mail.svg'
import copyLink from '../../Images/copy.svg'
import { useScrollTrigger } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext, { BaseUrl } from "../../Context/AuthContext";
import axios from "axios";

const PublicPostModule = ({ data }) => {

    const [bookmark, setBookmarkkk] = useState(0)

    const {
        user
    } = useContext(AuthContext);

    const id = localStorage.getItem("user")


    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null,
    );

    // const [, setBookMar] = useState(false)

    async function onHover() {
        console.log('jj')
        if (bookmark === 0) {
            setBookmarkkk(1)
        } else {
            setBookmarkkk(3)
        }
    }

    async function offHover() {
        if (bookmark === 1) {
            console.log('done')
            setBookmarkkk(0)
        } else if (bookmark === 2 || bookmark === 3) {
            setBookmarkkk(2)
        } else if (bookmark = 0) {
            setBookmarkkk(0)
        }
    }

    const location = useLocation();

    const [onActivity, setOnAct] = useState(location.pathname === '/viewProfileV2')



    useEffect(() => {
        async function subsOfCart() {
          const res = await axios.get(BaseUrl + `/bookmark`,
            {
              headers: { Authorization: `Bearer ${authTokens}` },
            }
          );
          const clss = res.data.postId
          const ids = clss.map(item => item.id);
          console.log(data.id , 'bookmark')
          console.log(ids, 'bookmark')
          console.log(ids.includes(data.id))
          if (ids.includes(data.id)){
            setBookmarkkk(2)
          }


        }
        if (user) {
          subsOfCart()
        }
      }, [])

    useEffect(() => {
        async function subsOfCart() {
            const res2 = await axios.get(BaseUrl + `/likedPost`,
                {
                    headers: { Authorization: `Bearer ${authTokens}` },
                }
            );
            console.log(res2)
            const clss2 = res2.data.postId
            const ids2 = clss2.map(item => item.id);
            console.log(ids2)
            if (ids2.includes(data.id)) {
                sethe(clikeIcon)
            }


        }
        if (user) {
            subsOfCart()
        }
    }, [])



    async function likeAPost(id) {
        const data2 = {
            id
        }
        const res2 = await axios.post(BaseUrl + `/likedPost`,
            data,
            {
                params: data,
                headers: { Authorization: `Bearer ${authTokens}` },
            }
        );
        sethe(clikeIcon)
    }

    async function unlikeAPost(id) {
        const data2 = {
            id
        }
        const res2 = await axios.patch(BaseUrl + `/likedPost`,
            data,
            {
                params: data,
                headers: { Authorization: `Bearer ${authTokens}` },
            }
        );
        sethe(likeIcon)
    }


    async function bookMarkk(id) {
        const data = {
            id
        }
        const res2 = await axios.post(BaseUrl + `/bookmark`,
            data,
            {
                params: data,
                headers: { Authorization: `Bearer ${authTokens}` },
            }
        );
        console.log(res2.data)
        setBookmarkkk(2)
    }

    async function unbookkmmaark(id) {
        const data = {
            id
        }
        const res2 = await axios.patch(BaseUrl + `/bookmark`,
            data,
            {
                params: data,
                headers: { Authorization: `Bearer ${authTokens}` },
            }
        );
        setBookmarkkk(0)
       
        
    }

    function timeAgo(dateString) {
        const now = new Date();
        const postDate = new Date(dateString);
        const diffInSeconds = Math.floor((now - postDate) / 1000);

        const intervals = [
            { label: 'year', seconds: 31536000 },
            { label: 'month', seconds: 2592000 },
            { label: 'week', seconds: 604800 },
            { label: 'day', seconds: 86400 },
            { label: 'hour', seconds: 3600 },
            { label: 'minute', seconds: 60 },
            { label: 'second', seconds: 1 }
        ];

        for (const interval of intervals) {
            const count = Math.floor(diffInSeconds / interval.seconds);
            if (count > 0) {
                return count === 1 ? `${count} ${interval.label} ago` : `${count} ${interval.label}s ago`;
            }
        }
        return 'just now';
    }


    const [openViewmore, setOpenViewMore] = useState(false)

    function onViewMoreClick(bool) {
        if (openViewmore !== bool) {
            setOpenViewMore(bool)
        } else {
            setOpenViewMore(false)
        }
    }

    const [reported, setReported] = useState(false)

    function onReport() {
        setReported(true);
        setOpenViewMore(false)

        // After 10 seconds (10000 ms), set reported back to false
        setTimeout(() => {
            setReported(false);
        }, 10000);
    }

    async function onDelete() {
        if (onActivity){
            const result = await axios.delete(`${BaseUrl}/publicPost?id=${data.id}`)
            
            setOpenViewMore(false)
        }
    }

    const [isTeacher, setIsTeacher] = useState(false)

    useEffect(() => {
        async function teachedVerify() {
            const res = await axios.get(BaseUrl + `/isTeacher?id=${data.user.id}`);
            setIsTeacher(res.data)
        }

        teachedVerify()

    }, [])

    const currentLink = window.location.href;

    const [shareOpen, setShareOper] = useState(false)

    function clickedOnShare() {
        setShareOper(!shareOpen)
    }

    const handleCopy = () => {
        const currentUrl = window.location.href;

        navigator.clipboard.writeText(currentUrl)
            .then(() => {
                alert('Current URL copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    const [iconhe, sethe] = useState(likeIcon)

    const nava = useNavigate()

    const [userProfile, setuserr] = useState({})

    useEffect(() => {
        async function getPr() {
            let reqOptions = {
                url: `${BaseUrl}/profileDetails/other?id=${data.user.id}`,
                method: "GET",
            }

            let response = await axios.request(reqOptions);
            setuserr(response.data)
        }

        getPr()
    },[])

    return (
        <div className="publicPostAtHomePage">
            <div className="publicPostHeader">
                <div className="PPHimgThumbnail">
                    <img src={data.user.img_thumbnail ? data.user.img_thumbnail : DefaultPic} alt="" />
                </div>
                <div>
                    <div className="nameATime">
                        <span className="PPHname fmfont" onClick={() => {
                            nava(`/otherProfile/${data.user.id}`)
                        }}>
                            {`${data.user.first_name} ${data.user.last_name}`}
                        </span>
                        <span className="PPHTime fmfont">
                            {timeAgo(data.created_at)}
                        </span>
                    </div>
                    <div className="HeadlinePPH frfont">
                        {userProfile.headline ? userProfile.headline : `${isTeacher ? 'Learner-Teacher' : 'Learner'}`}
                    </div>
                </div>
                <div className="dropDownPPH">
                    <img src={ViewMore}
                        onClick={() => {
                            if (openViewmore === data.id) {
                                onViewMoreClick(false)
                            } else {
                                onViewMoreClick(data.id)
                            }
                        }}
                        className="cp" alt="" />
                    {openViewmore === data.id && <div className="popupforviewmore  fmfont">
                        { !onActivity &&  <div onClick={onReport}>
                            Report
                        </div>}

                        {onActivity && <div onClick={onDelete}>
                            Delete
                        </div>}
                    </div>}
                </div>
            </div>

            {data.images.length > 0 &&
                <div className="mainBodyImg">

                    <img src={data.images[0]} alt="" />

                </div>
            }

            <div
                style={{
                    fontFamily: '"Noto Color Emoji", sans-serif'
                }}
                className={`descriptionPPh ${data.images.length > 0 ? 'yesImg' : 'noimg'}`} dangerouslySetInnerHTML={{ __html: data.caption }}>

            </div>

            <div className="PPHCTA">
                <div className="PPHCTA1">
                    <img src={iconhe} onClick={() => {
                        if (iconhe === likeIcon) {
                            likeAPost(data.id)
                        } else {
                            unlikeAPost(data.id)
                        }
                    }} className="opp cp" alt="" />
                    <img className="cp boooookkkmmmaaarrrrkkkk" src={bookmark === 0 ? bookmark1 : bookmark === 1 ? bookmark2 : bookmark === 2 ? bookmark3 : bookmark === 3 ? bookmark4 : ''}
                        onMouseEnter={() => {
                            onHover()
                        }}

                        onMouseLeave={() => {
                            offHover()
                        }}

                        onClick={() => {
                            if (bookmark === 0 || bookmark === 1) {
                                console.log('here', bookmark)
                                bookMarkk(data.id)
                            } else {
                                console.log('not here', bookmark)
                                unbookkmmaark(data.id)
                            }
                        }}
                        alt="" />
                </div>
                <div>
                    <img src={shareIcon} className="opp cp" onClick={clickedOnShare} alt="" />
                    {shareOpen && <div className="share-container">
                        <div className="share-options fmfont"
                            onClick={clickedOnShare}
                        >
                            <div
                                onClick={handleCopy}
                            >
                                <img src={copyLink} alt="" /> Copy Link
                            </div>
                            <a
                                href={`https://api.whatsapp.com/send?text=${encodeURIComponent(currentLink)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={whatsapp} alt="" />  WhatsApp
                            </a>
                            <a
                                href={`mailto:?subject=Check this out&body=${encodeURIComponent(currentLink)}`}
                            >
                                <img src={email} alt="" /> Email
                            </a>
                        </div>
                    </div>}
                </div>
            </div>

            {reported && <div className="reportedThePost fmfont">
                <div className="closer cp" onClick={() => { setReported(false) }}>
                    x
                </div>
                Thank you for taking the initiative We will look into the post by {`${data.user.first_name} ${data.user.last_name}`} and will get back to you.
                <div className="fsbfont">
                    Team Black box
                </div>
            </div>}

        </div>
    );
};

export default PublicPostModule;
